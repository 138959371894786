import React, { useState } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

const FormSection = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: #1e293b;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid #E2E8F0;
  border-radius: 24px;
  font-size: 16px;
  transition: all 0.2s;
  background: white;

  &:focus {
    outline: none;
    border-color: #0052FF;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: 1px solid #E2E8F0;
  border-radius: 24px;
  font-size: 16px;
  resize: vertical;
  min-height: 120px;
  transition: all 0.2s;
  background: white;

  &:focus {
    outline: none;
    border-color: #0052FF;
  }
`;

const StyledInputMask = styled(InputMask)`
  width: 100%;
  padding: 16px;
  border: 1px solid #E2E8F0;
  border-radius: 24px;
  font-size: 16px;
  transition: all 0.2s;
  background: white;

  &:focus {
    outline: none;
    border-color: #0052FF;
  }
`;

const SubmitButton = styled.button`
  background: #0052FF;
  color: white;
  padding: 16px 32px;
  border: none;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 82, 255, 0.15);

  &:hover {
    background: #0043cc;
    transform: translateY(-2px);
    box-shadow: 0 0 30px rgba(0, 67, 204, 0.25);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 0 20px rgba(0, 67, 204, 0.15);
  }
`;

const StatusMessage = styled.div`
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  background: ${props => props.error ? '#FEE2E2' : '#DCFCE7'};
  color: ${props => props.error ? '#DC2626' : '#16A34A'};
`;

const ContactForm = () => {
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    try {
      const response = await fetch('/send.php', {
        method: 'POST',
        body: formData
      });
      
      if (response.ok) {
        setStatus('Спасибо! Мы свяжемся с вами в ближайшее время.');
        e.target.reset();
        setTimeout(() => {
          setStatus('');
        }, 3000);
      } else {
        setStatus('Произошла ошибка. Пожалуйста, попробуйте позже.');
      }
    } catch (error) {
      setStatus('Произошла ошибка. Пожалуйста, попробуйте позже.');
    }
  };

  return (
    <FormSection>
      <StyledForm onSubmit={handleSubmit}>
        <InputGroup>
          <Label>Ваше имя *</Label>
          <Input
            type="text"
            name="name"
            required
            placeholder="Иван Петров"
          />
        </InputGroup>

        <InputGroup>
          <Label>Телефон *</Label>
          <StyledInputMask
            mask="+7 (999) 999-99-99"
            name="phone"
            required
            placeholder="+7 (___) ___-__-__"
          />
        </InputGroup>

        <InputGroup>
          <Label>Email *</Label>
          <Input
            type="email"
            name="email"
            required
            placeholder="example@domain.com"
          />
        </InputGroup>

        <InputGroup>
          <Label>Сообщение</Label>
          <TextArea
            name="message"
            placeholder="Введите ваше сообщение"
            rows="4"
          />
        </InputGroup>

        <SubmitButton type="submit">Отправить заявку</SubmitButton>
      </StyledForm>
      {status && <StatusMessage error={status.includes('ошибка')}>{status}</StatusMessage>}
    </FormSection>
  );
};

export default ContactForm; 